.edit-delete-board-btns-container{
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
}

.edit-board-btn-wrapper > button {
    background-color: gray;
    color: white;
    padding: 0.5rem;
    border-radius: 8px;
}

.edit-board-btn-wrapper > button:hover {
  background-color: rgb(64, 62, 62);

}

.delete-board-btn-wrapper > button {
    background-color: var(--pinterest-red);
    color: white;
    padding: 0.5rem;
    border-radius: 8px;
}

.delete-board-btn-wrapper > button:hover {
  background-color: red;
}

#remove-pin-btn {
  position: absolute;
  z-index: 1;
  color: white;
  background-color: transparent;
  border: none;
  font-size: 20px;
  padding: 0.5rem 0.8rem;
  right: 1.5%;
  top: 10px;
}

#remove-pin-btn:hover {
  background-color: rgb(65, 63, 63);
  border-radius: 50px;

}

.remove-btn-wrapper {
  position: relative;
  opacity: 0;
  height:5px;
}

.pin-card:hover .remove-btn-wrapper {
  opacity: 1;
}

/* masonry stuff */
.third-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /*gutter size offset */

    width: auto;
  }
  .third-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
  }
  
  .third-masonry-grid_column > div { 
    margin-bottom: 8px;
  }


  #board-description-ptag {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
