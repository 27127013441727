:root {
  --pinterest-red: rgb(230, 0, 35);
}

#edit-board-h1,
#edit-board-form,
.edit-board-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

#edit-board-form {
  padding: 3rem;
}

#delete-board-h1,
#delete-board-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.buttons-wrapper {
  display: flex;
  width: 10rem;
  justify-content: space-between;
}

.edit-board-errors-container {
  width: 15rem;
  text-align: center;
}

.edit-board-errors {
  color: red;
}

#edit-board-btn {
    padding: 0.5rem;
    width: 5rem;
    background-color: rgb(230, 0, 35, 70%);
    color: white;
    border-radius: 8px;
}

#edit-board-btn:hover {
  background-color: var(--pinterest-red);
}
