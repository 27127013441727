

*{
  box-sizing: border-box;
}

button {
  cursor:pointer;
}
ul {
  list-style: none;
}

.profile-dropdown {
  position: absolute;
  right: 4rem;
  border: 2px solid black;
  padding: 0 1rem;
  text-align: center;
  background-color: white;
  z-index: 1;
  
}

.hidden {
  display: none;
}

.links-and-profile-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.links-container {
  display: flex;
  gap: 1rem;
  margin-right: 1rem;
  align-items: center;
}

#profile-btn {
  margin-right: 5.2rem;
  border: none;
  background-color:white;
}

#profile-btn:hover {
  font-size: large;
}

.login-signup-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 40px;
}

.profile-group {
  display: flex;
  height:40px;
  margin-left:1rem;
  padding-left: 0.5rem
}

.home {
  font-size: 20px;
  color:var(--pinterest-red)
}

.home:hover {
  font-weight: 500;
}

#logo-img {
  width: 35px;
  height: 35px;
}




#nav-link-li {
  display: flex;
  align-items: center;
}

#create-link:hover {
  font-size:17px;
  font-weight: 500;
}

#user-link {
    font-size: 14px;
    padding: 0.7rem;
}

.user-link-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color:rgb(229, 227, 230);
  padding:0.3rem;
  border-radius: 50%;
  width:2rem;
  height:2rem;
  border: 1px solid gray;
  cursor:pointer;
}

.user-link-wrapper:hover {
  width: 2.3rem;
  height: 2.3rem;
  border: 5px solid rgb(163, 161, 161);

}

#dropdown-logout-btn {
  border:none;
  background-color: var(--pinterest-red);
  color:white;
  border-radius: 3px;
  width:100%
}

#dropdown-logout-btn:hover {
  background-color: #ad081b;
}

.profile-menu-selections {
  border-bottom: 1px solid gray;
  margin-bottom: 5px;
}


.login-btn-container > button , .signup-btn-container > button{
  padding: 0.5rem 0.8rem;
  border-radius: 20px;
  font-size: 16px;
  border: none;
}

.login-btn-container > button {
  background-color: var(--pinterest-red);
  color:white;
}

.signup-btn-container > button {
  background-color:#efefef;
}


.login-btn-container > button:hover {
  background-color: #ad081b;
}

.signup-btn-container > button:hover {
  background-color: #e2e2e2;
}

.about-btn-container > a:hover {
  border-bottom: 2px solid black;
  font-weight: 500;
}


#arrow-dropdown:hover {
  font-weight:bold;
}

.search-input-and-icon-container {
  border: 1px solid gray;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow:hidden;
  padding-right: 0.5rem;
  justify-content: space-between;
}

#search-icon {
  cursor: pointer;
}

#search-input {
  width: 30rem;
  border: none;
  padding-left: 1rem;
}

#search-input:focus {
  outline: none;
}

#search-form {
  display:flex;
}


@media screen and (max-width: 945px) {
  #search-form {
    width:50vw;
  }
}

@media screen and (max-width: 630px) {
  #search-form {
    width:40vw;
  }
}

@media screen and (max-width: 565px) {

  .home, #create-link {
    font-size: 3vw;
  }
  #search-input{
    width:80%
  }

}

@media screen and (max-width:535px) {
  #search-form {
    width:200px
  }
}

@media screen and (max-width:520px) {
  #search-form {
    width:185px
  }
}

@media screen and (max-width:505px) {
  .home.word-link {
    display:none;
  }

  .login-btn-container > button, .signup-btn-container > button, .about-btn-container > a {
    font-size:3vw;
  }

}


@media screen and (max-width:446px) {
  #search-form {
    width:170px;
  }
  .login-btn-container > button, .signup-btn-container > button, .about-btn-container > a{
    font-size:2.5vw;
  }
}

@media screen and (max-width:420px) {
  .links-and-profile-btn-container{
    height: 8rem;
    display:grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    padding: 0 0.3rem;
  }

  .login-signup-container {
    grid-row-start:2;
    grid-column: 1 / span 2;
    justify-content: space-evenly;

  }
  .login-btn-container > button, .signup-btn-container > button, .about-btn-container > a{
    width: 6rem;
    border-radius: 15px;
    font-size:3vw;
  }

  #nav-link-li {
    grid-column: 1 / span 2;
    justify-content: center;
  }

  .home.name {
    font-size: 8vw;
  }

  .profile-group{
    grid-column-start: 2;
    grid-column-end: -1;
    grid-row-start:1;
    grid-row-end:2;
    padding-left: 10rem;
  }
  #search-form {
    grid-row-start: 2;
    grid-column: 1 / span 2;
    grid-row-end: 3;
    width: 200%;
  }
  #search-input{
    width:100%;
  }
  #create-link{
    font-size:4vw;
  }
}


@media screen and (max-width:380px) {
  #search-form {
    width:205%;
  }
  .profile-group{
    padding-left: 8rem;
  }
}
@media screen and (max-width:375px) {
  #search-form {
    width:202%;
  }
}
@media screen and (max-width:365px) {
  #search-form {
    width:215%;
  }
  .login-signup-container {
    flex-direction: column;
    align-items: center;
  }
  .login-btn-container > button, .signup-btn-container > button, .about-btn-container > a{
    width: 10rem;
    border-radius: 15px;
    font-size: 3.5vw;
  }

}
@media screen and (max-width:360px) {
  #search-form {
    width:200%;
  }
}

@media screen and (max-width:350px) {
  #search-form {
    width:220%;
  }
  .profile-group{
    padding-left: 6rem;
  }
}
@media screen and (max-width:340px) {
  #search-form {
    width:230%;
  }
}
@media screen and (max-width:330px) {
  #search-form {
    width:240%;
  }
}
@media screen and (max-width:320px) {
  #search-form {
    width:255%;
  }
}
@media screen and (max-width:310px) {
  #search-form {
    width:265%;
  }
  .profile-group{
    padding-left: 4rem;
  }
}
@media screen and (max-width:294px) {
  #search-form {
    width:200%;
  }
}

@media screen and (max-width:274px) {
  .profile-group{
    padding-left: 1.5rem;
  }
}
