
@keyframes moveUp {
    from {
        transform: translateY(0px)
    }
    to {
        transform: translateY(-80px)
    }
}




@keyframes blink1 {
    0% {
    opacity: 0;
    }
    50% {
    opacity: 1;
    }
    100% {
    opacity: 0;
    }
}

.center-container{
    text-align: center;
    font-size: 2.5rem;
}

.pics {
    width: 180px;
    height: 350px;
    color: white;
    border-radius: 16px;
    object-fit: cover;

}

.pics-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.page-words-bottom {
    color:rgb(194,139, 0);
    margin: 0;
}

.main-word-section {
    margin-bottom: 100px;
}

.page-words-top {
    padding:0;
    margin-bottom: 0;
}

.dot {
    color: rgb(194,139, 0);
}


.pic-1 {
    animation-name: moveUp;
    animation-duration: 3.5s;
    animation-timing-function: linear;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode:forwards;
}

.pic-2 {
    animation-name: moveUp;
    animation-duration: 3.5s;
    animation-timing-function: linear ;
    animation-delay: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode:forwards;
}
.pic-3{
    animation-name: moveUp;
    animation-duration: 3.5s;
    animation-timing-function: linear ;
    animation-delay: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode:forwards;
}.pic-4 {
    animation-name: moveUp;
    animation-duration: 3.5s;
    animation-timing-function: linear ;
    animation-delay: 2.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode:forwards;
}
.pic-5 {
    animation-name: moveUp;
    animation-duration: 3.5s;
    animation-timing-function: linear ;
    animation-delay: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode:forwards;
}

.dot-1 {
    animation: blink1 3s linear 0.2s infinite
}

.dot-2 {
    animation: blink1 3s linear 0.4s infinite
}

.dot-3 {
    animation: blink1 3s linear 0.6s infinite

}


.splash-page-footer {
    align-self: flex-end;
    border-top: 1px solid gray;
    margin-top: 2rem;
}

.github-linkedin-links {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.github-linkedin-links > a > i {
    font-size: 1.5rem;
}

.github-linkedin-links > a > i:hover {
    font-size: 1.8rem;
}

@media screen and (max-width: 1000px) {

    .pics {
    width: 160px;
    height: 330px;
    }
}

@media screen and (max-width: 900px) {

    .pic-5 {
        display:none;
    }
}

@media screen and (max-width: 760px) {

    .pic-4 {
        display:none;
    }
}

@media screen and (max-width: 560px) {
    @keyframes moveUp {
        from {
            transform: translateY(0px)
        }
        to {
            transform: translateY(0px)
        }
    }
    
    .pics-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

    }
    .pic-4 {
        display:block
    }
    .pics {
        width: 100%
    }
}


@media screen and (max-width: 365px) {
    .pics-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

    }
    .pic-5 {
        display:block;
    }
}
