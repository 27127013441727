:root {
    --pinterest-red: rgb(230, 0, 35);
}

.board-card {
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.text-section {
    height: 30px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.container-for-boards {
    margin-top: 5rem;
    display: flex;
    gap: 1rem;
    padding: 0 1rem;
    flex-wrap: wrap;
    display:flex;
    justify-content:center;
}


.board-card-link {
    height: 165px;
}

.pin-image-1 {
    border-right: 1px solid white;
    grid-row-start:1;
    grid-row-end:3;
    width:100%;
    grid-column-start: 1;
    grid-column-end: 1;
    height: 187px;
    object-fit:cover;
}

.pin-image-2 {
    border-bottom: 1px solid white;
    width:100%;
    height: 93px;
    grid-row-start:1;
    grid-row-end:2;
    grid-column-start:2;
    grid-column-end:3;
    object-fit:cover;

}

.pin-image-3 {
width:100%;
    height:93px;
    grid-row-start:2;
    grid-row-end:3;
    grid-column-start:2;
    grid-column-end:3;
    object-fit:cover;

}


.pic-collage {
    border: 1px solid gray;
    width: 234px;
    height: 154x;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    border-radius: 16px;
    overflow:hidden;
}


.center {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}
.user-info-area {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
#username-ptag2 {
    margin: 1rem auto 0;
    font-size: 25px;;
}

#user-circle {
    border: 1px solid gray;
    padding: 1rem 1.5rem;
    font-size: 25px;
    margin: 0;
    border-radius: 50px;
    background-color: lightgray;
    cursor:default;
}

.under-user-info-area {
    display: flex;
    margin: 1rem;
    align-items: center;
    gap:1rem;
}
a {
    text-decoration:none;
    color: black;
}

.center > * {
    font-size: 18px;
}

.boards-link.selected {
    border-bottom: 2px solid black;
}


.board-name-ptag, .saved-ptag {
    font-weight: bold;
}



#created-pins-link:hover, .boards-link:hover {
    font-weight: 500
}

#create-board-btn-container {
    display: flex;
    justify-content: center;
    margin-top:1rem;
}

#create-board-btn-container > button {
    padding: 0.5rem;
    background-color: var(--pinterest-red);
    color:white;
    border-radius: 8px;
    border: none;
}

#create-board-btn-container > button:hover {
    background-color: red;
}


.saved-card {
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:234px;
}
.saved-card-link {
    border: 1px solid gray;
    border-radius: 16px;
    height: 187px;
    position: relative;
    overflow:hidden;
}

.saveImageDiv {
    width:55%;
    border:none;
    border-radius:16px;    
    height:187px;
    position: absolute;
    object-fit: cover;
}


.img-4 {
    right:0;
    background-color: lightblue;
    border:none;
}
.img-3 {
    right:15%;
    background-color: lightcoral;
    z-index:1;
    border:none;
    border-right: 1px solid white;
    
}
.img-2 {
    right:30%;
    background-color: lightgoldenrodyellow;
    z-index:2;
    border:none;
    border-right: 1px solid white;
    
}

.img-1 {
    left: 0%;
    background-color: lightskyblue;
    z-index:4;
    border:none;
    border-right: 1px solid white;


}
