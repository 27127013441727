:root {
  --pinterest-red: rgb(230, 0, 35);
}

#pin-creation-form {
  box-shadow: 2px 2px 2px gray;
  border: 1px solid gray;
  border-radius: 15px;
  width: 870px;
  display: flex;
  align-items: center;
  padding: 1rem;
  height: 645px;
}

.right-side {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  align-items: center;
  padding: 1rem;
  width: 50%;
}

.left-side {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

#save-btn {
  width: 5rem;
  background-color: rgb(230, 0, 35, 70%);
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 0 10px 10px 0;
}

.pin-creation-container {
  display: flex;
  justify-content: center;
}

.save-btn-container {
  display: flex;
  width: 90%;
  height: 3rem;
  justify-content: space-between;
}

.image-container {
  border: 2px solid black;
  height: 500px;
  width: 325px;
  border-radius: 20px;
  overflow: hidden;
}

#provided-pin-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.url-input {
  margin-top: 2rem;
  width: 15rem;
  outline-color:gray;
  border-radius: 5px;
  padding: 0.2rem;
}


.description-textarea,
.title-input {
  width: 90%;
}

.description-textarea {
  border-radius: 20px;
  margin-top: 30px;
  padding: 15px;
}

.title-input {
  border: none;
  border-bottom: 1px solid gray;
  font-size: 1.5rem;
}

.title-input:focus {
  outline: none;
}

.create-pin-errors {
  padding: 0;
  margin: 0;
  color: red;
}

.pin-errors-container {
  padding: 1rem;
}



.create-board-btn-container > button {
    padding: 0.5rem;
    height: 100%;
}

#save-btn:hover {
  background-color: var(--pinterest-red);
}

#no-img-pic {
  width:100%;
  height: 100%;
  object-fit: contain;
}

/* this is the file upload button */
input[type="file"]{
  cursor: pointer;
}

.select-and-save {
  display: flex;
}

.select-and-save > button, .select-and-save > select {
  height: 100%;
}

@media screen and (max-width: 940px) {
  #pin-creation-form{
    width:750px;
  }
}

@media screen and (max-width: 800px) {
  #pin-creation-form{
    width:650px;
  }
  .image-container{
    width:90%;
  }
  .save-btn-container {
    flex-direction: column;
    height: 5rem;
    align-items: center;
  }
}

@media screen and (max-width: 700px) {
  #pin-creation-form{
    width:600px;
  }
}

@media screen and (max-width: 640px) {
  #pin-creation-form{
    width:550px;
  }
}

@media screen and (max-width: 580px) {
  #pin-creation-form{
    flex-direction:column;
    height: 1290px;
    width: 500px;
  }
  .left-side {
    width: 100%;
    height: 50%;
  }
  .right-side {
    height: 50%;
    width: 100%;
  }
  .save-btn-container{
    flex-direction: row;
    height: 3rem;

  }
  .save-btn-container > * {
    height: 100%;
  }
  
  

}

@media screen and (max-width: 540px) {
  #pin-creation-form{
    width: 470px;
  }
}

@media screen and (max-width: 525px) {
  #pin-creation-form{
    width: 430px;
  }
}

@media screen and (max-width: 486px) {
  #pin-creation-form{
    width: 400px;
  }

}

@media screen and (max-width: 440px) {
  #pin-creation-form{
    width: 370px;
  }
  .save-btn-container{
    flex-direction: column;
    height: 5rem;
    gap: 0.3rem;

  }

}

@media screen and (max-width: 425px) {
  #pin-creation-form{
    width: 340px;
  }

}

@media screen and (max-width: 425px) {
  #pin-creation-form{
    width: 300px;
  }

}

@media screen and (max-width:347px) {
  #pin-creation-form{
    width: 285px;
    height: 900px;
  }
  .left-side {
    height: 30%
  }
  .image-container {
    height: 90%;
  }

  .right-side {
    height:70%;
  }
}
