* {
    box-sizing: border-box;
}

.everything-wrapper {
  margin: 1rem;
}

.card {
  border-radius: 16px;
  display:flex;
  overflow:hidden;
}

#pin-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card:hover {
  filter: brightness(40%);
}

/* masonry stuff */
.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /*gutter size offset */

    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
  }
  
  .my-masonry-grid_column > div { 
    margin-bottom: 8px;
  }

  .saving-btn-wrapper{
    position: relative;
    height: 5px
  }

  #saving-btn2 {
    position:absolute;
    z-index:2;
    right:1.5%;
    top: 10px;
    background-color: black;
    border: 0;
    color: white;
    opacity: 0;
    padding: 0.4rem 0.6rem;
    border-radius: 14px;
  
  }


#saving-btn {
  position:absolute;
  z-index:2;
  right:1.5%;
  top: 10px;
  background-color: var(--pinterest-red);
  border: 0;
  color: white;
  opacity: 0;
  padding: 0.4rem 0.6rem;
  border-radius: 14px;

}



.pin-card-frontpage:hover #saving-btn,
.pin-card-frontpage:hover #saving-btn2,
.pin-card-frontpage:hover #board-selector {
  opacity: 1;
}


#not-found-ptag {
  padding: 1rem;
}

.show {
  position:absolute;
  width: 50%;
  z-index: 2;
  opacity: 0;
  top: 10px;
  cursor: pointer;
  left: 2%;
  background-color: rgba(0,0,0,0.6);
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;

}

.noshow {
  position:absolute;
  width: 1.5rem;
  z-index: 2;
  opacity: 0;
  top: 10px;
  cursor: pointer;
  left: 2%;
  font-weight: 500;
  color: red;
  background-color: transparent;
  border: none;
}



#board-selector > option {
  background-color: white;
  color: black;
  border:none;
}
