:root {
  --pinterest-red: rgb(230, 0, 35);
  --hovered-red: #ad081b;
}

#edit-pin-h1,
#edit-pin-form,
.edit-pin-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

#edit-pin-form {
  padding: 3rem;
}

#delete-pin-modal {
  padding: 2rem;
}

#delete-pin-h1,
#delete-pin-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-modal-btns-wrapper {
  display: flex;
  width: 12rem;
  justify-content: space-between;
}

.board-select-label {
  display: flex;
  flex-direction: column;
  width: 10rem;
}

.edit-pin-errors-container {
  text-align: center;
  width: 15rem;
  margin: auto;
}

.edit-pin-errors {
  padding: 0;
  margin: 0;
  color: red;
}

#save-edit-btn {
    width: 5rem;
    background-color: rgb(230, 0, 35, 70%);
    color: white;
    border-radius: 8px;
    padding: 0.3rem;
}

#save-edit-btn:hover {
  background-color: var(--pinterest-red)
}

#cancel-btn, #delete-btn {
    padding: 0.5rem;
    width: 5rem;
    border-radius: 8px;
}

#cancel-btn {
  background-color: gray;
  color:white;
}

#cancel-btn:hover {
  background-color: rgb(64, 62, 62)
}

#delete-btn {
    background-color: rgb(230, 0, 35, 70%);
    color: white;
}
#delete-btn:hover {
  background-color: var(--pinterest-red);
}

.edit-delete-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}


.edit-btn-wrapper > button {
  width: 5rem;
  padding: 0.4rem;
  background-color: gray;
  color: white;
  border-radius: 8px;
  
}

.edit-btn-wrapper > button:hover {
  background-color: rgb(64, 62, 62)
}

.delete-btn-wrapper > button {
  width: 5rem;
  padding: 0.4rem;
  background-color: var(--pinterest-red);
  color: white;
  border-radius: 8px;
}

.delete-btn-wrapper > button:hover {
  background-color: red;
}

.edit-pin-inputs {
  width: 20rem;
}

/* masonry stuff */
.second-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /*gutter size offset */

    width: auto;
  }
  .second-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
  }
  .second-masonry-grid_column > div {
    margin-bottom: 10px;
  }


  
.right-text-section2 {
  height: 100%;
  width: 50%;
}

.container-for-created {
  margin-top: 5rem;
  padding: 0 1rem;
}

.created-pins-link.selected {
  border-bottom: 2px solid black;
}


.created-pins-link:hover, .boards-link:hover {
  font-weight: 500
}

@media screen and (max-width: 580px) {
  .right-text-section2 {
    height: 50%;
    width: 100%;
  }
}
