:root {
    --pinterest-red: rgb(230, 0, 35);
  }

#create-board-h1, #create-board-form, .create-board-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}



#create-board-form {
    padding: 2rem;
}

#create-board-btn {
    padding: 0.5rem;
    width: 5rem;
    background-color: rgb(230, 0, 35, 70%);
    color: white;
    border-radius: 8px;
}

#create-board-btn:hover {
    background-color: var(--pinterest-red);
}

.create-board-errors-container{
    width: 15rem;
    text-align: center;
}


.create-board-errors {
    color: red;
    padding: 0.5rem;
}
