#edit-comment-h1,
#edit-comment-form,
.edit-comment-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

#edit-comment-form {
    padding: 3rem;
    padding-top: 0;
  }

  #comment-label-and-area {
    width: 15rem;
  }

  .edit-comment-inputs {
    width: 100%;
  }
