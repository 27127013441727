:root {
  --pinterest-red: rgb(230, 0, 35);
  --hovered-red: #ad081b;
}

.sub-main-container {
  margin: auto;
  box-shadow: 2px 2px 2px gray;
  border: 1px solid gray;
  border-radius: 15px;
  width: 870px;
  display: flex;
  align-items: center;
  padding: 1rem;
  height: 645px;
}

#specific-pin-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.pin-left-side {
  width: 50%;
  height: 100%;
}

.right-text-section {
  height: 100%;
  width:50%;
}

.right-side-above-comments {
  width: 100%;
  height: 80%;
  padding: 1.5rem;
  overflow-y: scroll;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.left-img-container {
  border: 2px solid black;
  height: 600px;
  width: 100%;
  margin: auto;
  margin-top: 4px;
  border-radius: 20px;
  overflow: hidden;
}

#pin-description-ptag {
  text-align: justify;
}


#go-back-btn{
  margin-left: 5rem;
  margin-bottom: 2rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.1rem;
}

#saved-ptag, .empty-comment-error{
  color: red;
}

#save-pin-btn {
  background-color: rgb(230,0,35,70%);
  color: white;
  padding: 0.59rem;
  border-radius: 0 8px 8px 0;
  border:none;

}

#select-board-select {
  padding: 0.5rem;
}

#save-pin-btn:hover {
  background-color: var(--pinterest-red)
}


.comment-container {
  display: flex;
  gap: 1rem;
}

#username-ptag{
  font-weight: 500;
  margin:0.3rem;
}

#comment-ptag{
  overflow-wrap: anywhere;
  margin:0.3rem;
}


#make-comment-section{
  border:1px solid gray;
  display:flex;
  border-radius: 20px;
  overflow: hidden;

}

#comment-writing-textarea{
  width:90%;
  padding-left: 1rem;
  padding-top: 0.5rem;
  border: none;
}

#comment-writing-textarea:focus{
  outline: none;
}

#num-comments-ptag{
  border-top: 1px solid gray;
  padding-top: 5px;
  font-weight: 500;

}
#post-comment-btn{
  border:none;
  background-color: rgb(230, 0, 35, 70%);
  color:white;
  width:10%;
}

#post-comment-btn:hover {
  background-color: var(--pinterest-red)
}

.fa-paper-plane {
  transform: rotate(20deg);
}


.bottom-comment-area {
  padding: 0 1.5rem;
}


.edit-delete-btns-wrapper {
  display:flex;
  gap:0.3rem;
  align-items: center;
}

.edit-delete-btns-wrapper > * {
  border: none;
  padding: none;
  margin: none;
  font-size: 10px;
  background-color:transparent;
  color:rgb(76, 74, 74);
}


.edit-delete-btns-wrapper > *:hover {

  color:rgb(36, 36, 36);
  font-weight: 500;
}

#delete-comment-btn {
  cursor:pointer;
}

#pin-post-username-span {
  font-weight: 500;
  color: black;
}

#posted-by-div {
  color: gray;
}


@media screen and (max-width: 940px) {
  .sub-main-container{
    width:750px;
  }
}

@media screen and (max-width: 800px) {
  .sub-main-container{
    width:650px;
  }
}

@media screen and (max-width: 700px) {
  .sub-main-container{
    width:600px;
  }
}

@media screen and (max-width: 640px) {
  .sub-main-container{
    width:550px;
  }
}

@media screen and (max-width: 580px) {
  .sub-main-container{
    flex-direction:column;
    height: 1290px;
    width: 500px;
  }
  .pin-left-side {
    width: 100%;
    height: 50%;
  }
  .right-text-section {
    height: 50%;
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .sub-main-container{
    width: 470px;
  }
}

@media screen and (max-width: 525px) {
  .sub-main-container{
    width: 430px;
  }
}

@media screen and (max-width: 486px) {
  .sub-main-container{
    width: 400px;
  }

}

@media screen and (max-width: 440px) {
  .sub-main-container{
    width: 370px;
  }

}

@media screen and (max-width: 425px) {
  .sub-main-container{
    width: 340px;
  }

}

@media screen and (max-width: 425px) {
  .sub-main-container{
    width: 300px;
  }

}

@media screen and (max-width:347px) {
  .sub-main-container{
    width: 285px;
    height: 900px;
  }
  .pin-left-side {
    height: 30%
  }
  .left-img-container {
    height: 90%;
  }

  .right-text-section {
    height:70%;
  }
}
